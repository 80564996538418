import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LessonSidebar from './LessonSidebar';
import mermaid from 'mermaid';

function LessonSlider() {
    const [lesson, setLesson] = useState({ content: '', lessonId: '' });
    const [allLessons, setAllLessons] = useState([]);
    const { lessonId } = useParams();
    const navigate = useNavigate();
    const contentRef = useRef(null);

    useEffect(() => {
        // Fetch all lessons for the sidebar
        fetch('/api/lessons')
            .then(response => response.json())
            .then(data => setAllLessons(data));

        // Fetch the current lesson
        if (lessonId) {
            fetch(`/api/lesson/${lessonId}`)
                .then(response => response.json())
                .then(data => setLesson(data));
        }
    }, [lessonId]);

    useEffect(() => {
        if (contentRef.current) {
            mermaid.init(undefined, contentRef.current.querySelectorAll('.mermaid'));
        }
    }, [lesson.content]);

    const flattenLessons = (lessons) => {
        return lessons.reduce((acc, lesson) => {
            acc.push(lesson);
            if (lesson.children) {
                acc.push(...flattenLessons(lesson.children));
            }
            return acc;
        }, []);
    };

    const flatLessons = flattenLessons(allLessons);
    const currentIndex = flatLessons.findIndex(l => l.id === lessonId);
    const prevLesson = currentIndex > 0 ? flatLessons[currentIndex - 1] : null;
    const nextLesson = currentIndex < flatLessons.length - 1 ? flatLessons[currentIndex + 1] : null;

    const handleNavigation = (direction) => {
        const targetLesson = direction === 'prev' ? prevLesson : nextLesson;
        if (targetLesson) {
            navigate(`/lesson/${targetLesson.id}`);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row" style={{ minHeight: 'calc(100vh - 100px)' }}> {/* Adjust 100px based on your header/footer height */}
                <div className="col-md-3 p-0">
                    <LessonSidebar lessons={allLessons} currentLessonId={lessonId} />
                </div>
                <div className="col-md-9">
                    <div ref={contentRef} dangerouslySetInnerHTML={{ __html: lesson.content }} />
                    <div className="navigation mt-4">
                        <button 
                            onClick={() => handleNavigation('prev')} 
                            className="btn btn-primary me-2" 
                            disabled={!prevLesson}
                        >
                            Previous
                        </button>
                        <button 
                            onClick={() => handleNavigation('next')} 
                            className="btn btn-primary" 
                            disabled={!nextLesson}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LessonSlider;