import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LessonSidebar({ lessons, currentLessonId }) {
  const [expandedLessons, setExpandedLessons] = useState(() => {
    // Initialize from sessionStorage if available, otherwise empty object
    const saved = sessionStorage.getItem('expandedLessons');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    // Update expanded state based on the current lesson
    const newExpandedState = { ...expandedLessons };
    const expandPath = (lesson) => {
      if (lesson.id === currentLessonId || (lesson.children && lesson.children.some(child => expandPath(child)))) {
        newExpandedState[lesson.id] = true;
        return true;
      }
      return false;
    };
    lessons.forEach(expandPath);
    setExpandedLessons(newExpandedState);
    // Save to sessionStorage
    sessionStorage.setItem('expandedLessons', JSON.stringify(newExpandedState));
  }, [lessons, currentLessonId]);

  const toggleExpand = (lessonId) => {
    setExpandedLessons(prev => {
      const newState = {
        ...prev,
        [lessonId]: !prev[lessonId]
      };
      // Save to sessionStorage
      sessionStorage.setItem('expandedLessons', JSON.stringify(newState));
      return newState;
    });
  };

  const renderLessons = (lessonList, level = 0) => {
    return (
      <ul className={`list-unstyled ${level > 0 ? 'ps-3' : ''}`}>
        {lessonList.map((lesson) => {
          const isExpanded = expandedLessons[lesson.id];
          const hasChildren = lesson.children && lesson.children.length > 0;
          return (
            <li key={lesson.id} className="mb-2">
              <div className="d-flex align-items-center">
                {hasChildren && (
                  <button 
                    className="btn btn-sm p-0 me-2" 
                    onClick={() => toggleExpand(lesson.id)}
                    style={{ width: '20px', height: '20px', lineHeight: '1' }}
                  >
                    {isExpanded ? '−' : '+'}
                  </button>
                )}
                {!hasChildren && <span className="me-2" style={{ width: '20px' }}></span>}
                <Link 
                  to={`/lesson/${lesson.id}`} 
                  className={`${currentLessonId === lesson.id ? 'fw-bold' : ''} ${level === 0 ? 'fs-5' : ''} text-decoration-none`}
                >
                  {lesson.title}
                </Link>
              </div>
              {hasChildren && isExpanded && (
                <div className="mt-2">
                  {renderLessons(lesson.children, level + 1)}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="lesson-sidebar pe-3" style={{
      height: 'calc(100vh - 100px)', // Adjust this value based on your header/footer height
      overflowY: 'auto',
      position: 'sticky',
      top: '20px', // Adjust this value to set the distance from the top of the viewport
    }}>
      {renderLessons(lessons)}
    </div>
  );
}

export default LessonSidebar;