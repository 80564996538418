import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogPosts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/api/posts')
      .then(response => response.json())
      .then(data => setPosts(data));
  }, []);

  return (
    <div className="bg-white p-4 rounded shadow-sm main-content">
      <h3>This is the library of the posts of the blog</h3>
      <ul>
        {posts.map(post => (
          <li key={post.slug}>
            <Link to={`/post/${post.slug}`}>{post.title}</Link> <small>{post.timestamp}</small>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BlogPosts;