import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function BlogPost() {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    fetch(`/api/post/${slug || ''}`)
      .then(response => response.json())
      .then(data => setPost(data));
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="bg-white p-4 rounded shadow-sm main-content" dangerouslySetInnerHTML={{ __html: post.content }} />
  );
}

export default BlogPost;