import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import mermaid from 'mermaid';
import BlogPost from './components/BlogPost';
import BlogPosts from './components/BlogPosts';
import Sidebar from './components/Sidebar';
import LessonSlider from './components/LessonSlider';
import './style.css';
import './custom.css';

function App() {
  const [topLevelLessons, setTopLevelLessons] = useState([]);

  useEffect(() => {
    fetch('/api/lessons/top-level')
      .then(response => response.json())
      .then(data => setTopLevelLessons(data));

    // Initialize mermaid
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
    });
  }, []);

  return (
    <Router basename="/">
      <div className="container-fluid px-0">
        <header className="py-3 mb-4 border-bottom">
          <div className="container d-flex flex-wrap justify-content-between align-items-center">
            <Link to="/" className="d-flex align-items-center text-decoration-none">
              <img src="/static/img/headshot_simonedisomma.jpg" alt="Simone Di Somma" className="rounded-circle me-2" width="40" height="40" />
              <span className="fs-4 p-1 invertito">Simone Di Somma</span>
            </Link>
            <nav>
              <ul className="nav nav-pills custom-nav">
                <li className="nav-item"><NavLink to="/" className={({ isActive }) => isActive ? "nav-link active custom-link" : "nav-link custom-link"} end>Blog</NavLink></li>
                <li className="nav-item"><NavLink to="/blog-posts" className={({ isActive }) => isActive ? "nav-link active custom-link" : "nav-link custom-link"}>Posts</NavLink></li>
                <li className="nav-item"><NavLink to="/post/about-me" className={({ isActive }) => isActive ? "nav-link active custom-link" : "nav-link custom-link"}>About me</NavLink></li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle custom-link" href="#" id="lessonsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Lessons
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="lessonsDropdown">
                    {topLevelLessons.map((lesson) => (
                      <li key={lesson.id}>
                        <Link to={`/lesson/${lesson.slug}`} className="dropdown-item custom-link">
                          {lesson.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <div className="container">
          <Routes>
            <Route path="/" element={
              <div className="row">
                <div className="col-lg-8 order-lg-2 mb-4">
                  <BlogPost />
                </div>
                <div className="col-lg-4 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            } />
            <Route path="/blog-posts" element={
              <div className="row">
                <div className="col-lg-8 order-lg-2 mb-4">
                  <BlogPosts />
                </div>
                <div className="col-lg-4 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            } />
            <Route path="/post/:slug" element={
              <div className="row">
                <div className="col-lg-8 order-lg-2 mb-4">
                  <BlogPost />
                </div>
                <div className="col-lg-4 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            } />
            <Route path="/lesson/:lessonId" element={<LessonSlider />} />
            <Route path="*" element={<BlogPost />} />
          </Routes>
        </div>

        <footer className="bg-light text-center py-3 mt-4">
          <p className="mb-0">Copyright © <strong>2000</strong> ↔ <strong>2024</strong> <strong>Simone Di Somma</strong> 🚀</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;